.group-and {
   display: flex;
   flex-direction: column;
   /* background-color: bl8ue; */
}

.item {
   display: flex;
   width: 100%;
   justify-content: space-between;
   border-bottom: 1px solid grey;
}

.item:first-of-type {
   border-top: 1px solid grey;
}

.item-title {
   width: 100%;
   /* margin-left: 1em; */
}

.item-type {
   min-width: 2em;
   display: flex;
   justify-content: center;
}

.item-move {
   margin-left: 1em;
   margin-right: 1em;
}